/*
Before this we were using remark-gfm, this is just a copy of that, but with the modification that allows us to disable
extensions. This was required for React 18 which includes stricter hydration errors, causing us to error when gfm
transformed an email to a link inside a message block with a URL.
 */

const autolinkLiteral = require('mdast-util-gfm-autolink-literal/from-markdown')
const strikethrough = require('mdast-util-gfm-strikethrough/from-markdown')
const table = require('mdast-util-gfm-table/from-markdown')
const taskListItem = require('mdast-util-gfm-task-list-item/from-markdown')

const own = {}.hasOwnProperty

module.exports = configure

function configure(markdownConfig) {
    const extensions = [
        strikethrough,
        table,
        taskListItem
    ];

    if (markdownConfig.allowGfmLinks) {
        extensions.unshift(autolinkLiteral);
    }

    const config = {transforms: [], canContainEols: []}
    const length = extensions.length
    let index = -1

    while (++index < length) {
        extension(config, extensions[index])
    }

    return config
}

function extension(config, extension) {
    let key
    let left
    let right

    for (key in extension) {
        left = own.call(config, key) ? config[key] : (config[key] = {})
        right = extension[key]

        if (key === 'canContainEols' || key === 'transforms') {
            config[key] = [].concat(left, right)
        } else {
            Object.assign(left, right)
        }
    }
}
