import {Editor, Range, Transforms} from "slate";
import {isMissingProtocol} from "../../../../utils/SchoolBlocksUtilities";

export const editLink = (editor, url) => {
    if (isLinkActive(editor)) {
        unwrapLink(editor);
    }
    const { selection } = editor;
    const textSelection = Editor.string(editor, selection);
    wrapLink(editor, url, textSelection);
};

export const isLinkActive = editor => {
    const [link] = Editor.nodes(editor, { match: n => n.type === "link" });
    return !!link;
};

export const getActiveLink = editor => {
    const [link] = Editor.nodes(editor, { match: n => n.type === "link" });
    return link?.[0] || false;
}

export const unwrapLink = editor => {
    Transforms.unwrapNodes(editor, {
        match: n => n.type === "link",
    });
};

const wrapLink = (editor, url, text) => {

    if (isMissingProtocol(url)) {
        url ="http://" + url;
    }

    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link = {
        type: "link",
        url,
        children: isCollapsed ? [{ text }] : []
    };

    if (isCollapsed) {
        Transforms.insertNodes(editor, link);
    } else {
        Transforms.wrapNodes(editor, link, { split: true });
        Transforms.collapse(editor, { edge: "end" });
    }

};
