import { Editor, Transforms, Text } from "slate";

export const LIST_TYPES = ["ol_list", "ul_list"];
const headingDepth = 3;

export const isBlockActive = (editor, formats = []) => {
    const [match] = Editor.nodes(editor, {
        match: n => {
            if (formats.indexOf("ol_list") > -1) {
                return n.ordered === true;
            } else if (formats.indexOf("ul_list") > -1) {
                return n.type === "list" && n.ordered === false;
            }
            return formats.indexOf(n.type) > -1;
        },
    });

    return !!match;
};

export const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
};

export const toggleBlock = (editor, format) => {
    const isList = LIST_TYPES.includes(format);
    const isActive = isBlockActive(editor, [format]);
    const isChangingListType = isList &&
        (
            (format === "ul_list" && isBlockActive(editor, ["ol_list"]))
            || (format === "ol_list" && isBlockActive(editor, ["ul_list"]))
        )

    if (!isList && format !== "blockquote") {
        // if it's not a list or a blockquote, things are simple. we can just change the node type
        if (format === "heading" && !isActive) {
            Transforms.setNodes(editor, {
                type: format,
                depth: headingDepth,
            })
        } else {
            Transforms.setNodes(editor, {
                type: isActive ? 'paragraph' : format,
                depth: undefined,
            })
        }
    } else {
        // with lists or blockquotes, we want to preserve paragraph nodes
        // if it's a heading, we want to change the node type altogether
        // with a list, first we need to unwrap the ol/ul nodes, this leaves us with <li><p>text</p></li>
        // just in case we're switching list types, we want to unwrap any list type, not just the selected one
        Transforms.unwrapNodes(editor, {
            match: n => {
                if (['table', 'tableRow'].includes(n.type)) {
                    return true;
                }
                return isList ? n.type === "list" : n.type === format
            },
            mode: 'all',
            split: true,
        });

        // from here, there are three conditions:
        // 1: we're going from an active list state to another active list state, in which case we just need to wrap
        // all list items with the intended list type
        if (isChangingListType) {
            Transforms.wrapNodes(editor, {
                type: "list",
                ordered: format === "ol_list",
            }, {
                match: n => n.type === 'listItem',
            });
        } else if (isActive && isList) {
            // 2: we're going from an active list state to an inactive list state, in which case at this point we just need
            // to unwrap any remaining list items
            Transforms.unwrapNodes(editor, {
                match: n => n.type === 'listItem',
            })
        } else if (!isActive) {
            // 3: we're going from an inactive state to an active state, in which case we need to wrap in the intended state
            // and then follow up by wrapping any list items
            if (isList) {
                Transforms.wrapNodes(editor, {
                    type: "list",
                    ordered: format === "ol_list",
                });
            } else {
                Transforms.wrapNodes(editor, {
                    type: format,
                });
            }

            if (isList) {
                Transforms.wrapNodes(editor, {
                    type: 'listItem',
                    // TODO: double check the below line.  It looks like it should be in the third argument
                    //   to the wrapNodes call; NodeOptions.
                    //match: n => Text.isText(n),
                })
            }
        }
    }
};

export const toggleMark = (editor, format) => {
    // there's a bug (I think in the serializer) which causes the editor to blow up when toggling italic inside a bold text block
    // adding this here for now to disallow that until we can find a fix
    if (format === "strong") {
        const isBoldActive = isMarkActive(editor, "strong");
        const isItalicActive = isMarkActive(editor, "emphasis");

        if (isBoldActive) {
            Editor.removeMark(editor, "strong");
        } else if (isItalicActive) {
            Editor.removeMark(editor, "emphasis");
            Editor.addMark(editor, "strong", true);
        } else {
            Editor.addMark(editor, "strong", true);
        }
    } else if (format === "emphasis") {
        const isBoldActive = isMarkActive(editor, "strong");
        const isItalicActive = isMarkActive(editor, "emphasis");

        if (isItalicActive) {
            Editor.removeMark(editor, "emphasis");
        } else if (isBoldActive) {
            Editor.removeMark(editor, "strong");
            Editor.addMark(editor, "emphasis", true);
        } else {
            Editor.addMark(editor, "emphasis", true);
        }
    }
};
