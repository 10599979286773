/*
Before this we were using remark-gfm, this is just a copy of that, but with the modification that allows us to disable
extensions. This was required for React 18 which includes stricter hydration errors, causing us to error when gfm
transformed an email to a link inside a message block with a URL.
 */

const autolinkLiteral = require('mdast-util-gfm-autolink-literal/to-markdown')
const strikethrough = require('mdast-util-gfm-strikethrough/to-markdown')
const table = require('mdast-util-gfm-table/to-markdown')
const taskListItem = require('mdast-util-gfm-task-list-item/to-markdown')
const configure = require('mdast-util-to-markdown/lib/configure')

module.exports = toMarkdown

function toMarkdown(options, markdownConfig) {
    const extensions = [strikethrough, table(options), taskListItem];

    if (markdownConfig.allowGfmLinks) {
        extensions.unshift(autolinkLiteral);
    }

    const config = configure(
        {handlers: {}, join: [], unsafe: [], options: {}},
        {
            extensions: extensions,
        }
    )

    return Object.assign(config.options, {
        handlers: config.handlers,
        join: config.join,
        unsafe: config.unsafe
    })
}
