import unistVisitParents from "unist-util-visit-parents";
import unistRemove from 'unist-util-remove';
import remarkSqueezeParagraphs from 'remark-squeeze-paragraphs';
import remarkRemoveComments from 'remark-remove-comments';
import {isExternalLink} from "../../utils/SchoolBlocksUtilities";
import rehypeUrls from 'rehype-urls';
import {IMarkdownConfig} from "./conversions";

const fromMarkdown = require("./fromMarkdown");
const toMarkdown = require("./toMarkdown");
const micromarkGfm = require("./micromarkGfm");

const debugMarkdown: boolean = false;

function disableCodeIndented() {
    // We don't want spaces to be interpreted as code blocks. Users may have unintentionally added spaces for indentation
    // and without this it will show up as a code block.
    var data = this.data()

    add('micromarkExtensions', {disable: {null: ['codeIndented']}});

    function add(field, value) {
        if (data[field]) data[field].push(value)
        else data[field] = [value]
    }
}

function gfm(config) {
    const data = this.data()

    function add(field, value) {
        /* istanbul ignore if - other extensions. */
        if (data[field]) data[field].push(value)
        else data[field] = [value]
    }

    add('micromarkExtensions', micromarkGfm({}, config));
    add('fromMarkdownExtensions', fromMarkdown(config));
    add('toMarkdownExtensions', toMarkdown({}, config));
}

export function customRemarkPlugins(config: IMarkdownConfig) {
    this
        .use(gfm, config)
        .use(disableCodeIndented)
        .use(remarkSqueezeParagraphs)
        .use(remarkRemoveComments)
        .use(() => {
            const textFormatters = [
                'strong',
                'emphasis',
                'delete',
            ];

            return transformer;
            function transformer(tree) {
                if (debugMarkdown) {
                    // for debugging
                    console.debug('old MDAST node: ', JSON.stringify(tree));
                }

                const tests = [
                    {type: 'break'},
                    {type: 'text'},
                ];
                unistVisitParents(tree, tests, visitor);

                function visitor(node, ancestors) {
                    const lastIndex = (ancestors?.length || 0) - 1;
                    switch (node.type) {
                        case 'break':
                            if (lastIndex >= 0 && textFormatters.includes(ancestors[lastIndex].type)) {
                                // TODO: need to move it, not delete it
                                node._delete = true;
                            }
                            break;
                    }
                }

                //@ts-ignore
                unistRemove(tree, {_delete: true});
                if (debugMarkdown) {
                    // for debugging
                    console.debug('new MDAST node: ', JSON.stringify(tree));
                }
                return tree;
            }
        })

}

export function customRehypePlugins(config: IMarkdownConfig = {}) {
    this
        // @ts-ignore
        .use(rehypeUrls, function(url, node) {
            if (isExternalLink(url.href, config.currentFullUrl || "")) {
                node.properties.target = "_blank";
                node.properties.rel = "nofollow noopener noreferrer";
            }
        })
}
