import classNames from "classnames";
import { useSlate } from "slate-react";
import {Point} from 'slate';
import Button from "../utilities/Button";
import {
    isMarkActive,
    isBlockActive,
    toggleMark,
    toggleBlock,
} from "./commands";
import styles from "./styles/SlateEditor.module.scss";
import {isLinkActive} from "./plugins/links";
import PropTypes from "prop-types";

export const ButtonWrapper = (props: {
    className?: string,
    active?: boolean,
    [restProps: string]: any,
}) => {
    const {className = "", active = false, ...restProps} = props;
    const buttonClassName = classNames({
        [styles.button]: true,
        [styles.buttonActive]: active,
        [className]: className,
    });

    return <Button {...restProps} className={buttonClassName} />;
};

export const BlockButton = props => {
    const { format } = props;
    const editor = useSlate();
    const blockButtonClassNames = classNames("fa", {
        "fa-list-ul": format === "ul_list",
        "fa-list-ol": format === "ol_list",
        "fa-check-square": format === "check-list-item",
        "fa-heading": format === "heading",
        "fa-quote-left": format === "blockquote",
    });

    return (
        <ButtonWrapper
            className={blockButtonClassNames}
            active={isBlockActive(editor, [format])}
            onMouseDown={event => {
                event.preventDefault();
                toggleBlock(editor, format);
            }}
        />
    );
};


export const MarkButton = props => {
    const { format } = props;
    const editor = useSlate();

    const markButtonClassNames = classNames("fa", {
        "fa-bold": format === "strong",
        "fa-italic": format === "emphasis",
    });

    return (
        <ButtonWrapper
            className={markButtonClassNames}
            active={isMarkActive(editor, format)}
            onMouseDown={event => {
                event.preventDefault();
                toggleMark(editor, format);
            }}
        />
    );
};

export const LinkButton = props => {
    const markButtonClassNames = classNames("fa", `fa-link`);
    const editor = useSlate();

    const selectionHasActiveLink = isLinkActive(editor);

    return (
        <ButtonWrapper
            className={markButtonClassNames}
            active={selectionHasActiveLink}
            disabled={selectionHasActiveLink ? false : (!props.activeLinkSelection || Point.equals(props.activeLinkSelection.focus, props.activeLinkSelection.anchor))}
            onClick={event => {
                event.preventDefault();
                props.setEditLinkViewOpen(true);
            }}
        />
    );
};

MarkButton.propTypes = {
    format: PropTypes.string.isRequired,
};

BlockButton.propTypes = {
    format: PropTypes.string.isRequired,
};
