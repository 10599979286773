import {Transforms} from "slate";
import {htmlToSlate} from "../../../markdown/conversions";

export const withInlines = editor => {
    const { isInline } = editor;

    // treat links and line breaks as inline elements as opposed to block elements
    // this retains more of the whitespace that someone enters with HTML -> Markdown
    // and makes sure links flow inline with text.
    editor.isInline = element => {
        return ["link", "break"].includes(element.type) ? true : isInline(element);
    };

    return editor;
};

export const voidElementTypes = ["thematicBreak"]
export const withVoids = editor => {
    const {isVoid} = editor;

    editor.isVoid = element => voidElementTypes.includes(element.type) ? true : isVoid(element);

    return editor;
}

export const withCopyPaste = editor => {
    const {insertData} = editor;

    editor.insertData = data => {
        const html = data.getData('text/html');

        if (html) {
            const slate = htmlToSlate(html);
            Transforms.insertFragment(editor, slate)
            return
        }

        insertData(data);
    }

    return editor;
}
