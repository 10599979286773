import classNames from 'classnames';
import {forwardRef, useEffect, useState} from "react";
import styles from './styles/SlateEditor.module.scss';
import {BlockButton, LinkButton, MarkButton} from "./buttons";
import {EditLinkToolbar} from "./EditLinkToolbar";
import {useSlate} from "slate-react";
import {getActiveLink} from "./plugins/links";

const Menu = forwardRef(({className, ...props}, ref) => {
    const menuClassName = classNames({
        [className]: className,
        [styles.menu]: true,
    });
    return <div
        {...props}
        ref={ref} className={menuClassName}
    />
});

const Toolbar = (props) => {
    const [editLinkViewOpen, setEditLinkViewOpen] = useState(false);
    const [linkUrl, setLinkUrl] = useState("");
    const [activeLinkSelection, setActiveLinkSelection] = useState(null);

    const toolbarClassName = classNames({
        [props.className]: props.className,
        [styles.toolbar]: true,
        [styles.toolbarExpand]: editLinkViewOpen,
    });

    const editor = useSlate();

    useEffect(() => {
        if (editor.selection && editLinkViewOpen) {
            setEditLinkViewOpen(false);
        }

        // we want to preserve the activeLink (for editing) and selection (for wrapping/unwrapping) so we don't
        // lose it when entering/leaving edit link view
        if (editor.selection) {
            const {url} = getActiveLink(editor);
            setLinkUrl(url);
            setActiveLinkSelection(editor.selection);
        }

    }, [editor.selection]);

    return <Menu
        className={toolbarClassName}
        style={{top: props.toolbarOffset.offsetTop, left: props.toolbarOffset.offsetLeft}}
    >
        {editLinkViewOpen ? <EditLinkToolbar
            linkUrl={linkUrl}
            setLinkUrl={setLinkUrl}
            activeLinkSelection={activeLinkSelection}
            setActiveLinkSelection={setActiveLinkSelection}
            setEditLinkViewOpen={setEditLinkViewOpen}
        /> : <><BlockButton format="heading"/>
            <BlockButton format="ul_list"/>
            <BlockButton format="ol_list"/>
            <BlockButton format="blockquote"/>
            <MarkButton format="strong"/>
            <MarkButton format="emphasis"/>
            <LinkButton activeLinkSelection={activeLinkSelection} setEditLinkViewOpen={setEditLinkViewOpen} />
        </>}
        {typeof props.arrowLeft === 'number' && <span className={styles.arrow} style={{left: props.arrowLeft}}/>}
    </Menu>
};

export default Toolbar;
