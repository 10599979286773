import unified from "unified";
import {mdastToSlate, slateToMdast} from "remark-slate-transformer";
import rehypeParse from "rehype-parse";
import rehype2Remark from 'rehype-remark';
import remarkParse from "remark-parse";
import remark2Rehype from "remark-rehype";
import {customRemarkPlugins, customRehypePlugins} from "./plugins";
import patternInScope from 'mdast-util-to-markdown/lib/util/pattern-in-scope.js';
import {defaultValue} from "../editors/MarkdownEditor";
import {Node, Editor} from "slate";
import remarkStringify from 'remark-stringify';
import rehypeStringify from "rehype-stringify";
import rehypeRaw from "rehype-raw";

// The below import is left in here as a warning.  We should use `import('unist')` when
// looking for types that are not global (for whatever reason).  Note how it's used
// throughout the file like `import('unist').Node` by Typescript.  This is NOT the same thing
// as Webpack's dynamic import() statement.  It's processed by Typscript and then replaced.
// https://davidea.st/articles/typescript-2-9-import-types
//import * as unist from 'unist';

function safetySlate(slate) {
    // despite the Slate typings, Slate does not except a Text Node at the root level for its value - this prevents that error
    if (JSON.stringify(slate) === JSON.stringify([{text: ""}])) {
        return defaultValue;
    }
    // Need to add a paragraph below thematic breaks to allow for editing.
    // It cannot be selected, and the user can’t add text below it unless they are editing in markdown.
    // This is a known issue with slate version 0.62.0. https://github.com/ianstormtaylor/slate/issues/4301
    if (slate[slate.length-1].type === 'thematicBreak') {
        slate.push(JSON.parse(JSON.stringify(defaultValue[0])))
    }
    return slate;
}

export type IMarkdownConfig = {
    currentFullUrl?: string,
    allowGfmLinks?: boolean,
}

export function slateToMarkdown(slate: Node[], config: IMarkdownConfig = {}): string {
    return mdastToMarkdown(slateToMdast({children: slate} as Editor), config);
}

export function markdownToHtml(md: string, config: IMarkdownConfig = {}): string {
    return unified()
        .use(rehypeStringify)
        .use(customRehypePlugins, config)
        .stringify(mdastToHast(markdownToMdast(md, config), config));
}

export function htmlToMarkdown(html: string, config: IMarkdownConfig = {}): string {
    const processedFile = unified()
        .use(remarkParse)
        .use(remark2Rehype, { allowDangerousHtml: true })
        .use(rehypeRaw as any) // *Parse* the raw HTML strings embedded in the tree
        .use(rehypeStringify)
        .processSync(html);

    return mdastToMarkdown(hastToMdast(htmlToHast(String(processedFile), config), config), config);
}

export function htmlToSlate(html: string, config: IMarkdownConfig = {}): Node[] {
    //@ts-ignore
    return safetySlate(mdastToSlate(hastToMdast(htmlToHast(html, config), config)));
}

export function markdownToSlate(md: string, config: IMarkdownConfig = {}): Node[] {
    //@ts-ignore
    return safetySlate(mdastToSlate(markdownToMdast(md, config)));
}

function hastToMdast(hast: import('unist').Node, config: IMarkdownConfig): import('unist').Node {
    return unified()
        .use(rehype2Remark)
        .use(customRemarkPlugins, config)
        .runSync(hast);
}

function mdastToHast(mdast: import('unist').Node, config: IMarkdownConfig): import('unist').Node {
    return unified()
        .use(remark2Rehype)
        .use(customRehypePlugins, config)
        .runSync(mdast);
}

function markdownToMdast(md: string, config: IMarkdownConfig): import('unist').Node {
    return unified()
        .use(remarkParse)
        .use(customRemarkPlugins, config)
        .parse(md);
}

function htmlToHast(html: string, config: IMarkdownConfig): import('unist').Node {
    return unified()
        .use(rehypeParse, {fragment: true, emitParseErrors: true})
        .use(rehypeRaw as any)
        .use(customRehypePlugins, config)
        .parse(html);
}

function mdastToMarkdown(mdast: import('unist').Node, config: IMarkdownConfig): string {
    return unified()
        .use(remarkStringify, {
            handlers: {
                "break": function (node, parent, context, safe) {
                    let index = -1

                    while (++index < context.unsafe.length) {
                        // If we can’t put eols in this construct (setext headings, tables), use a
                        // space instead.
                        if (
                            context.unsafe[index].character === '\n' &&
                            patternInScope(context.stack, context.unsafe[index])
                        ) {
                            return /[ \t]/.test(safe.before) ? '' : ' '
                        } else if (/(?:\n)\n/g.test(safe.before + "\n" + safe.after)) {
                            // disallow back to back line breaks - this causes a couple issues including the potential for
                            // rendering a backslash when there are 3 back to back breaks, or the weird line break issue from SB-1886
                            return "";
                        }
                    }

                    return '\\\n'
                }
            },
        })
        .use(customRemarkPlugins, config)
        .stringify(mdast);
}
