/*
Before this we were using remark-gfm, this is just a copy of that, but with the modification that allows us to disable
extensions. This was required for React 18 which includes stricter hydration errors, causing us to error when gfm
transformed an email to a link inside a message block with a URL.
 */

const combine = require('micromark/dist/util/combine-extensions')
const autolink = require('micromark-extension-gfm-autolink-literal')
const strikethrough = require('micromark-extension-gfm-strikethrough')
const table = require('micromark-extension-gfm-table')
const tasklist = require('micromark-extension-gfm-task-list-item')

module.exports = create

function create(options, markdownConfig) {
    const extensions = [strikethrough(options), table, tasklist];

    if (markdownConfig.allowGfmLinks) {
        extensions.unshift(autolink);
    }

    return combine(extensions);
}
