import {SetStateAction, useRef} from 'react';
import FontAwesome from "../utilities/FontAwesome";
import {editLink, unwrapLink} from "./plugins/links/commands";
import {useSlate} from "slate-react";
import {Range, Transforms} from "slate";
import {ButtonWrapper} from "./buttons";

export function EditLinkToolbar(props: {
    setEditLinkViewOpen: SetStateAction<any>,
    linkUrl: string,
    setLinkUrl: SetStateAction<any>,
    setActiveLinkSelection: SetStateAction<any>,
    activeLinkSelection: Range,
}) {
    const editor = useSlate();

    const initializedAsEmptyUrl = useRef(typeof props.linkUrl !== "string");

    function handleAddLink(e) {
        e.stopPropagation();
        Transforms.select(editor, props.activeLinkSelection);
        editLink(editor, props.linkUrl);
        props.setEditLinkViewOpen(false);
    }

    return <div>
        <input type={'text'} placeholder={'Paste or type a link'} value={props.linkUrl} autoFocus
               onChange={e => props.setLinkUrl(e.target.value)}/>
        <ButtonWrapper
            title={"Add Link"}
            disabled={!props.activeLinkSelection}
            onClick={handleAddLink}
        >
            <FontAwesome name={'fa-check'} prefix={'fas'}/>
        </ButtonWrapper>
        <ButtonWrapper title={"Go Back"} onClick={e => {
            e.stopPropagation();
            props.setEditLinkViewOpen(false)
        }}>
            <FontAwesome name={'fa-times'} prefix={'fas'} />
        </ButtonWrapper>
        <ButtonWrapper title={"Remove Link"} disabled={initializedAsEmptyUrl.current} onClick={e => {
            e.stopPropagation();
            Transforms.select(editor, props.activeLinkSelection);
            unwrapLink(editor);
            props.setEditLinkViewOpen(false);
            // need to revert these values
            props.setLinkUrl("");
            props.setActiveLinkSelection(null);
        }}><FontAwesome name={'fa-unlink'} prefix={'fas'}/></ButtonWrapper>
    </div>
}
