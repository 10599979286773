import isHotkey from "is-hotkey";
import {
    forwardRef,
    useCallback,
    useEffect,
    useMemo,
    useState,
    useRef,
    useImperativeHandle,
} from "react";
import {createEditor, Range, Transforms, Element as SlateElement, Editor} from "slate";
import {Editable, Slate, withReact, ReactEditor} from "slate-react";
import {withHistory} from "slate-history";
import {isBlockActive, toggleMark} from "./commands";
import Toolbar from "./Toolbar";
import styles from "./styles/SlateEditor.module.scss";
import classNames from "classnames";
import {withInlines, withVoids} from "./plugins/links";
import ClickableLink from "../utilities/ClickableLink";
import escapeHtml from "escape-html";
import {debounce} from "../../utils/CleversiteUtilities";
import {withCopyPaste} from "./plugins/links/links";
import {markdownToSlate, slateToMarkdown} from "../markdown/conversions";
import FontAwesome from "../utilities/FontAwesome";

const HOTKEYS = {
    "mod+b": {
        value: "bold",
        type: "mark",
    },
    "mod+i": {
        value: "italic",
        type: "mark",
    },
    "mod+u": {
        value: "underline",
        type: "mark",
    },
    "mod+s": {
        value: "strikethrough",
        type: "mark",
    },
    "shift+enter": {
        value: "\n",
        type: "text",
    },
    "option+enter": {
        value: "\n",
        type: "text",
    },
    "ctl+enter": {
        value: "\n",
        type: "text",
    },
};

export interface MarkdownEditorRef {
    forceUpdateState: (md: string) => void,
}

const Element = props => {
    const {attributes, children, element} = props;

    switch (element.type) {
        case "thematicBreak":
            return <div>
                {children}
                <hr {...attributes} contentEditable={false}/>
            </div>;
        case "blockquote":
            return <blockquote {...attributes}>{children}</blockquote>;
        case "heading":
            switch (element.depth) {
                case 1:
                    return <h1 {...attributes}>{children}</h1>;
                case 2:
                    return <h2 {...attributes}>{children}</h2>;
                case 3:
                    return <h3 {...attributes}>{children}</h3>;
                case 4:
                    return <h4 {...attributes}>{children}</h4>;
                case 5:
                    return <h5 {...attributes}>{children}</h5>;
                case 6:
                default:
                    return <h6 {...attributes}>{children}</h6>;
            }
        case "list":
            if (element.ordered) {
                return <ol {...attributes}>{children}</ol>;
            } else {
                return (
                    <ul {...attributes} style={element.style}>
                        {children}
                    </ul>
                );
            }
        case "link":
            return (
                <ClickableLink {...attributes} href={escapeHtml(element.url)}>
                    {children}
                </ClickableLink>
            );
        case "listItem":
            return <li {...attributes}>{children}</li>;
        case "table":
            return <table {...attributes}>{children}</table>;
        case "tableRow":
            return <tr {...attributes}>{children}</tr>;
        case "tableCell":
            return <td {...attributes}>{children}</td>;
        default:
            return <p {...attributes}>{children}</p>;
    }
};

const Leaf = ({attributes, children, leaf}) => {
    // Empty Leaf nodes should be considered a "hard break" (<br/>)
    if (Array.isArray(children) && children.length === 0 && leaf.text.trim() === "") {
        return <br/>;
    }

    if (leaf.strong) {
        children = <strong>{children}</strong>;
    }

    if (leaf.emphasis) {
        children = <em>{children}</em>;
    }

    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    if (leaf.delete) {
        children = <del>{children}</del>;
    }

    return <span {...attributes}>{children}</span>;
};

export const defaultValue = [
    {
        type: "paragraph",
        children: [{text: ""}],
    },
];

const toolbarHeight = 50;
const toolbarWidth = 280;

interface SlateElementType extends SlateElement {
    type: string;
}

const withSplitListNodes = (editor: Editor) => {
    // overwrite default behavior so when we list is selected, we're splitting the list_item rather than the selected paragraph node

    editor.insertBreak = () => {
        Transforms.splitNodes(editor, {
            always: true,
            match: isBlockActive(editor, ['listItem']) ? (n: SlateElementType) => n.type === 'listItem' : undefined,
        });
    };

    return editor
};

const MarkdownEditor = (props: {
    value: string,
    className?: string,
    editorClassName?: string,
    handleTextChange: (value: string, editor?: Editor) => void,
    placeholder?: string,
    maxLength?: number,
    editable?: boolean,
}) => {
    const {maxLength = 200000, editable = true} = props;
    const [editInMarkdown, setEditInMarkdown] = useState(false);
    const [toolbarOffset, setToolbarOffset] = useState({offsetTop: 0, offsetLeft: 0});
    const [arrowLeft, setArrowLeft] = useState<number | null>(null);

    // we need to preserve the selection in state because
    // when the link editor input is clicked it disappears from the editor instance.
    // if we preserve it, we can then set it later when adding the link
    const [selection, _setSelection] = useState<Range | null>(null);
    const [value, setValue] = useState(markdownToSlate(props.value))

    const withMaxLength = useCallback((editor) => {
        const { insertText } = editor;
        editor.insertText = (text) => {
            if (Editor.string(editor, []).length < maxLength) {
                insertText(text);
            } else {
                console.log('max limit reached!');
            }
        };
        return editor;
    }, []);

    const editor = useMemo(
        () => withInlines(withVoids(withCopyPaste(withHistory(withReact(withSplitListNodes(withMaxLength(createEditor()))))))),
        []
    );

    function setSelection() {
        editor.selection = selection;
    }

    const renderElement = useCallback(props => <Element {...props} />, []);
    const renderLeaf = useCallback(props => <Leaf {...props} />, []);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const slateEditorRef = useRef<HTMLDivElement | null>(null)

    function forceUpdateState(md) {
        editor.selection = null; // important to fix issue of lag between value changing and editor adjusting selection, see https://github.com/ianstormtaylor/slate/issues/3575
        setValue(markdownToSlate(md));
    }

    useEffect(() => {
        if (selection && Range.isExpanded(selection)) { // show toolbar if there is a selection OR currently editing
            const sr = ReactEditor.toDOMRange(editor, selection).getBoundingClientRect();
            const containerRect = containerRef.current?.getBoundingClientRect();

            if (containerRect) {
                const offsetTop = sr.top - containerRect.top - toolbarHeight;
                let offsetLeft = sr.left - containerRect.left - (toolbarWidth / 2) + sr.width / 2;
                let _arrowLeft = (toolbarWidth / 2);

                // handle cases where the container is smaller than where the toolbar wants to go
                if (offsetLeft < containerRect.left) {
                    _arrowLeft = sr.left - containerRect.left + sr.width / 2;
                    offsetLeft = 0;
                } else if (offsetLeft + toolbarWidth > containerRect.width) {
                    _arrowLeft -= (containerRect.width - offsetLeft - toolbarWidth);
                    offsetLeft = containerRect.width - toolbarWidth;
                }

                setArrowLeft(_arrowLeft);
                setToolbarOffset({offsetLeft, offsetTop});
            }
        }
    }, [selection]);

    useEffect(() => {
        const scrollHeight = textareaRef.current?.scrollHeight
        if (textareaRef.current && scrollHeight) {
            textareaRef.current.style.height= scrollHeight.toString() + "px"
        }
    }, [textareaRef])

    useEffect(() => {
        const editor = slateEditorRef.current
        if (!editable && editor) {
            // @ts-ignore
            const child_style = editor.children[0].style
            child_style.minHeight = '0px';
        }
    }, [slateEditorRef])

    function resetToolbar() {
        setToolbarOffset({offsetTop: 0, offsetLeft: 0});
        setArrowLeft(null);
    }

    const handleSelection = useCallback(debounce(() => {
        if (editor.selection && Range.isExpanded(editor.selection)) {
            _setSelection(editor.selection);

        } else if (editor.selection && !Range.isExpanded(editor.selection)) {
            resetToolbar();
        }
    }, 250) as () => void, []);

    const handleTextChange = useCallback(debounce((value) => {
        props.handleTextChange(slateToMarkdown(value));
    }, 750) as (value: string) => void, []);

    const onEditorChangeHandler = (value) => {
        handleSelection();

        // prevent firing on selection changes - taken from https://github.com/ianstormtaylor/slate/issues/3567
        const ops = editor.operations.filter(o => {
            if (o) {
                return o.type !== 'set_selection';
            }
            return false;
        });

        if (ops && Array.isArray(ops) && ops.length > 0) {
            setValue(value);
            handleTextChange(value);
        }
    };

    const containerClassName = classNames({
        [styles.container]: true,
        [styles.containerRawMarkdown]: editInMarkdown,
        [styles.containerEditable]: editable,
        "notranslate": true,
        [props.className || ""]: props.className,
    });

    const editorClassName = classNames({
        [styles.editor]: true,
        [props.editorClassName || ""]: props.editorClassName,
    })

    return (
        <div className={containerClassName} ref={containerRef}>
            {editInMarkdown ? <div className={editorClassName}>
                <textarea
                    placeholder={props.placeholder || "Enter some rich text..."}
                    value={props.value}
                    rows={editable ? 8 : undefined}
                    ref={textareaRef}
                    maxLength={maxLength}
                    onChange={e => {
                        forceUpdateState(e.target.value);
                        props.handleTextChange(e.target.value);
                    }}
                    disabled={!editable}
                    style={editable? {}: {resize: "none"}}
                />
            </div> : <Slate
                initialValue={value}
                editor={editor}
                onChange={onEditorChangeHandler}
            >
                {editable && <Toolbar toolbarOffset={toolbarOffset} setSelection={setSelection}
                          selection={selection} arrowLeft={arrowLeft}
                          closeToolbar={resetToolbar}/>}
                <div className={editorClassName} ref={slateEditorRef}>
                    <Editable
                        placeholder={props.placeholder || "Enter some rich text..."}
                        readOnly = {!editable}
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        onKeyDown={(event) => {
                            for (const hotkey in HOTKEYS) {
                                // @ts-ignore
                                if (isHotkey(hotkey, event)) {
                                    event.preventDefault();
                                    const action = HOTKEYS[hotkey];
                                    if (action.type === 'mark') {
                                        toggleMark(editor, action.value);
                                    } else if (action.type === 'text') {
                                        editor.insertText(action.value);
                                    }
                                }
                            }
                        }}
                    />
                </div>
            </Slate>}
            {editable &&
                <>
                    <button
                        onClick={() => setEditInMarkdown(v => !v)}>{editInMarkdown ? "Edit in Rich Text" : "Edit in Markdown (advanced)"}</button>
                    {editInMarkdown || <span
                        className={styles.tooltip}
                        data-content={"Markdown is a lightweight markup\nlanguage used by SchoolBlocks.\nNote that not all markdown elements\nare supported."}
                        style={{marginLeft: ".25rem"}}
                    >
                <FontAwesome prefix={'fas'} name={'fa-question-circle'}/>
            </span>}
                </>}
        </div>
    );
};

export default MarkdownEditor;
